
import { defineComponent, PropType } from 'vue'
import { mapActions } from 'vuex'
import { AreaWithBins } from '@/types/area'
import Loading from '@/components/shared/Loading.vue'
import HeaderBar from '@/components/shared/Header.vue'
import AreaDetailsMap from '@/components/areas/AreaDetailsMap.vue'
import AreaDetailsBinItem from '@/components/areas/AreaDetailsBinItem.vue'

export default defineComponent({
  name: 'FarmDetails',
  components: {
    Loading,
    HeaderBar,
    BinItem: AreaDetailsBinItem,
    Map: AreaDetailsMap
  },
  props: {
    farmId: {
      type: String as PropType<string>,
      required: true
    }
  },
  data () {
    return {
      currentArea: {} as AreaWithBins,

      // utilities
      loading: false
    }
  },
  created () {
    this.fetchArea()
  },
  methods: {
    ...mapActions(['getDashboardArea']),

    fetchArea () {
      this.loading = true
      this.getDashboardArea(this.farmId).then(response => {
        this.currentArea = response.data
      }).finally(() => {
        this.loading = false
      })
    },
  }
})
