
import {defineComponent, PropType} from 'vue'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { URLS } from '@/common/urls-enum'
import { Bin } from '@/types/bin'
import { AreaWithBins } from '@/types/area'

export default defineComponent ({
  name: 'AreaDetailsMap',
  props: {
    area: {
      type: Object as PropType<AreaWithBins>,
      required: true
    }
  },
  data () {
    return {
      map: null as any,
      binsWithCoordinates: [] as Array<Bin>,
      markersCoordinates: [] as Array<[number, number]>,
      mapCenter: [41.966900078, 2.835891520] as [number, number],
      mapZoom: 2,
      mapOptions: {
        zoomSnap: 0.5,
        maxZoom: 18,
        minZoom: 2,
        animate: true
      }
    }
  },
  updated () {
    if (this.area.bins) this.setBinsWithExistingCoordinates()
    this.setZoom()
    this.setMap()
    if (this.area.bins) this.setMarkers()
  },
  methods: {
    setBinsWithExistingCoordinates () {
      this.binsWithCoordinates = this.area.bins.filter((bin: Bin) => Boolean(bin.coordinates.latitude))
    },

    setZoom () {
      // if there is only 1 single location focus on it
      if (this.binsWithCoordinates.length === 1) {
        this.mapCenter = [
          this.binsWithCoordinates[0].coordinates.latitude,
          this.binsWithCoordinates[0].coordinates.longitude
        ]
        this.mapZoom = 16.5
      // otherwise set bounds in the map
      } else if (this.binsWithCoordinates.length > 1) {
        this.markersCoordinates = this.binsWithCoordinates.map(bin => {
          return [bin.coordinates.latitude, bin.coordinates.longitude]
        })
      // if no bins zoom to area coordinates
      } else {
        this.mapCenter = [
          this.area.location.coordinates.latitude,
          this.area.location.coordinates.longitude
        ]
        this.mapZoom = 16.5
      }
    },
    setMap () {
      this.map = L.map('map_wrap').setView(this.mapCenter, this.mapZoom)
      L.tileLayer(URLS.MAP_LAYER_SATELLITE, this.mapOptions).addTo(this.map)
      if (this.markersCoordinates.length) {
        this.map.fitBounds(this.markersCoordinates, { padding: [30, 30] })
      }
    },
    setMarkers () {
      for (const bin of this.binsWithCoordinates) {
        L.marker(
          [bin.coordinates.latitude, bin.coordinates.longitude],
          {icon: this.setMarkerIcons()}
        ).addTo(this.map);
      }
    },
    setMarkerIcons (): L.Icon<L.IconOptions> {
      return L.icon({
        iconUrl: require('@/assets/images/map-marker--base-blue.svg'),
        iconSize: [24, 32],
        iconAnchor: [12, 32]
      });
    }
  }
})
