
import { defineComponent, PropType } from 'vue'
import Chart, { ChartData, ChartOptions, ChartType, Tick, TooltipItem, ChartTypeRegistry } from 'chart.js/auto'
import { DateTime } from 'luxon'
import { User } from '@/types/user'
import { Bin, BinDataStockTimeseries } from '@/types/bin'
import dateTimeFormatting from '@/common/mixins-date-time'
import calculations from '@/common/mixins-calculations'
import utilities from '@/common/mixins-utilities'
import BinColorConfig from '@/common/bin-color-config'

export interface GraphTooltip {
  eta: number;
  dcr: number;
  stock: number;
}

export default defineComponent ({
  name: 'AreaDetailsBinItem',
  mixins: [
    dateTimeFormatting,
    calculations,
    utilities
  ],
  props: {
    bin: {
      type: Object as PropType<Bin>,
      required: true
    }
  },
  data () {
    return {
      operator: JSON.parse(localStorage.getItem('insylo_operator') || '{}') as User,
      accordionTarget: {} as HTMLElement,

      // chart
      chartType: 'line' as ChartType,
      chartData: {} as ChartData,
      chartOptions: {} as ChartOptions,

      // utilities
      listReady: false,
    }
  },
  computed: {
    getSiloImage (): string {
      return BinColorConfig(
        this.bin,
        // this.binVolumeRemainingPercentage(this.binCurrentVolume(this.bin.data.measurements), this.bin.specs.estimatedVolume),
        this.binCapacityRemainingPercentage(this.bin.specs.estimatedVolume, this.binRecipe(this.bin.data.stock).density, this.kilosToTonnes(this.binCurrentWeight(this.bin.data.stock)), this.bin.weight.fixedMaxCapacity),

        'binImg'
      )
    },
    graphDataReady (): boolean {
      return Boolean(
        this.bin.data.stock &&
        this.bin.data.stock.timeseries.avg_dcr &&
        this.bin.data.stock.timeseries.dcr &&
        this.bin.data.stock.timeseries.eta &&
        this.bin.data.stock.timeseries.stock &&
        this.bin.data.stock.timeseries.ts
      )
    },
    accordionAnimationClass (): string {
      if (this.listReady) return 'accordion-animated'
      return ''
    }
  },
  mounted () {
    this.setBinTileHeight()
    if (this.graphDataReady) {
      this.fillGraphData()
      this.fillGraphOptions()
      this.createChart()
    }
  },
  methods: {
    // shortened methods called from mixins
    isUsingBinThresholds (): boolean {
      return this.useBinThresholds(this.operator.useBinThresholds, this.bin.specs.useThresholdPercent)
    },
    isBinFullOrEmpty (): string {
      return this.binFullEmpty(
        this.binCurrentVolume(this.bin.data.measurements),
        this.bin.specs.estimatedVolume,
        this.bin.specs.fullThresholdPercent,
        this.bin.specs.emptyThresholdPercent
      )
    },
    binTextColorCss (): string {
      return BinColorConfig(
        this.bin,
        // this.binVolumeRemainingPercentage(this.binCurrentVolume(this.bin.data.measurements), this.bin.specs.estimatedVolume),
        this.binCapacityRemainingPercentage(this.bin.specs.estimatedVolume, this.binRecipe(this.bin.data.stock).density, this.kilosToTonnes(this.binCurrentWeight(this.bin.data.stock)), this.bin.weight.fixedMaxCapacity),

        'binTextColor'
      )
    },

    // chart.js settings
    fillGraphData () {
      this.chartData = {
        labels: this.setGraphLabels(),
        datasets: [
          // stock levels
          {
            fill: false,
            borderColor: '#aaaaaa',
            borderWidth: 2,
            pointRadius: 5,
            pointHoverRadius: 5,
            pointBorderWidth: 2,
            pointBackgroundColor: '#ffffff',
            data: this.setGraphValues(),
            xAxisID: 'xLabels'
          },
          // stock prediction
          {
            fill: false,
            borderColor: '#aaaaaa',
            borderWidth: 2,
            borderDash: [6, 6],
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            pointBorderWidth: 0,
            data: this.setGraphPredictionValues()
          },
          // threshold line Full
          {
            fill: false,
            borderColor: 'rgba(138,168,79,0.5)',
            borderWidth: 1,
            borderDash: [4, 4],
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            pointBorderWidth: 0,
            data: this.setGraphThreshold(this.thresholdMassFull())
          },
          // threshold line Empty
          {
            fill: false,
            borderColor: 'rgba(223,49,20,0.4)',
            borderWidth: 1,
            borderDash: [4, 4],
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            pointBorderWidth: 0,
            data: this.setGraphThreshold(this.thresholdMassEmpty())
          }
        ]
      }
    },
    fillGraphOptions () {
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 0
        },
        scales: {
          // labels
          xLabels: {
            grid: {
              display: false,
              borderColor: 'hsl(0,0%,70%)'
            },
            ticks: {
              padding: 0,
              font: {
                size: 12,
              },
              color: '#999999',
              minRotation: 45,
              maxRotation: 90
            }
          },
          // vertical line that marks last reading date
          xMark: {
            position: 'top',
            grid: {
              drawBorder: false,
              color: 'hsl(0,0%,90%)'
            },
            ticks: {
              padding: 0,
              font: {
                lineHeight: 0
              },
              callback: (tickValue: (number | string), index: number, ticks: Tick[]) => {
                const todayLabelIndex = ticks.length - 3
                return (index === todayLabelIndex) ? '' : null
              }
            }
          },
          y: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            suggestedMax: this.maxWeightAxisValue(),
            grid: {
              drawBorder: false,
              borderDash: [4, 4],
              color: 'hsl(0,0%,89%)'
            },
            ticks: {
              font: {
                size: 12,
              },
              color: '#999999',
              padding: 8,
              callback: (tickValue: (number | string)) => {
                return tickValue + ' t'
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            bodySpacing: 4,
            callbacks: {
              title: () => '',
              beforeLabel: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
                if (this.isUsingBinThresholds()) {
                  if (this.setGraphTooltips()[tooltipItem.dataIndex].stock < this.thresholdMassEmpty()) {
                    return 'Empty'
                  } else if (this.setGraphTooltips()[tooltipItem.dataIndex].stock > this.thresholdMassFull()) {
                    return 'Full'
                  }
                }
                return this.setGraphTooltips()[tooltipItem.dataIndex].stock + ' t'
              },
              label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
                return this.setGraphTooltips()[tooltipItem.dataIndex].eta + ' d'
              },
              afterLabel: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
                return this.setGraphTooltips()[tooltipItem.dataIndex].dcr + ' t/day'
              }
            }
          }
        }
      }
    },

    // chart creation and calculations
    createChart () {
      const chartContainer = this.$refs.chartContainer as HTMLCanvasElement
      const chartConfig = {
        type: this.chartType,
        data: this.chartData,
        options: this.chartOptions
      }
      new Chart(chartContainer, chartConfig)
    },
    setGraphValues (): number[] {
      const stocks: number[] = this.bin.data.stock.timeseries.stock
      return stocks.map(weight => {
        return this.kilosToTonnes(weight)
      })
    },
    setGraphPredictionValues (): number[] {
      const averageDecrements: number[] = this.bin.data.stock.timeseries.avg_dcr
      let lastAverageDecrement: number = averageDecrements[averageDecrements.length - 1]
      lastAverageDecrement = this.kilosToTonnes(lastAverageDecrement)
      const weightValues: number[] = this.setGraphValues()
      // set 2 extra predicted stock values
      for (let i = 0; i < 2; i++)  {
        let predictedWeight: number = weightValues[weightValues.length - 1] - lastAverageDecrement
        if (predictedWeight < 0) {
          predictedWeight = 0
        } else {
          predictedWeight = Math.round(predictedWeight * 100) / 100
        }
        weightValues.push(predictedWeight)
      }
      return weightValues
    },
    setGraphLabels (): string[] {
      const timestamps: string[] = this.bin.data.stock.timeseries.ts
      const lastDay = timestamps[timestamps.length - 1]
      const dayLabels = timestamps.map(date => {
        return DateTime.fromISO(date).toFormat('LLL dd')
      })
      dayLabels[dayLabels.length - 1] += '*'
      // set 2 extra future days for prediction data
      for (let i = 1; i <= 2; i++)  {
        const nextDay = DateTime.fromISO(lastDay).plus({ days: i }).toFormat('LLL dd')
        dayLabels.push(nextDay)
      }
      return dayLabels
    },
    setGraphTooltips (): GraphTooltip[] {
      const timeseries: BinDataStockTimeseries = this.bin.data.stock.timeseries
      const tooltips: GraphTooltip[] = []
      for (const index in timeseries.eta) {
        tooltips.push({
          eta: timeseries.eta[index],
          dcr: this.kilosToTonnes(timeseries.dcr[index]),
          stock: this.kilosToTonnes(timeseries.stock[index])
        })
      }
      return tooltips
    },
    setGraphThreshold (thresholdMass: number): number[] {
      if (this.isUsingBinThresholds()) {
        const thresholdLineArray: number[] = []
        for (let i = 0; i < this.setGraphLabels().length; i++) {
          thresholdLineArray.push(thresholdMass)
        }
        return thresholdLineArray
      }
      return []
    },
    thresholdMassFull (): number {
      if (!this.isUsingBinThresholds()) return 0
      return Math.round(this.bin.data.stock.maxCapacity * this.bin.specs.fullThresholdPercent / 1000) / 100
    },
    thresholdMassEmpty (): number {
      if (!this.isUsingBinThresholds()) return 0
      return Math.round(this.bin.data.stock.maxCapacity * this.bin.specs.emptyThresholdPercent / 1000) / 100
    },
    maxWeightAxisValue (): number {
      return Math.ceil(this.bin.data.stock.maxCapacity / 1000)
    },

    isOperationGood (): boolean {
      if (this.bin.data.operations && this.bin.data.operations.status && this.bin.data.operations.status.status) {
        const status = this.bin.data.operations.status.status
        return (status !== '0_ALERT_DETECTED')
      }
      return true
    },
    // if device is not sending data for more then 24h
    deviceNotSendingData (): boolean {
      if (this.bin.data.stock && this.bin.data.stock.last_update) {
        const sinceLastReading = (Date.now() - Date.parse(this.timeInOperatorTimezone(this.bin.data.stock.last_update))) / 3600000
        if (sinceLastReading > 24) return true
      }
      return false
    },

    // accordion
    setBinTileHeight () {
      this.accordionTarget = this.$refs.accordionTarget as HTMLElement
      this.accordionTarget.style.maxHeight = this.accordionTarget.scrollHeight + "px"
      this.listReady = true
    },
    runBinTileAccordion (event: Event) {
      const accordionTrigger: HTMLElement = event.currentTarget as HTMLElement
      if (this.accordionTarget.style.maxHeight) {
        this.accordionTarget.style.maxHeight = ''
      } else {
        this.accordionTarget.style.maxHeight = this.accordionTarget.scrollHeight + "px"
      }
      accordionTrigger.classList.toggle('closed')
    }
  }
})
